export default {
  profissao: null,
  estadoCivil: null,
  regimeCasamento: null,
  conjugeNome: null,
  conjugeCpf: null,
  conjugeRg: null,
  conjugeProfissao: null,
  nomeMae: null,
  nomePai: null,
  juntaComercial: null
}
