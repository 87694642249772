<template>
  <div class="arrematante-analises-analise">
    <e-row>
      <e-col>
        <erp-s-field view="tl" label="Data da Análise">
          <strong>{{analise.createdAt|formatDate}}</strong>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field view="tl" label="Operador">
          <strong>{{analise.createdByName}}</strong>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field view="tl" label="Tipo">
          <strong class="text-uppercase">{{analise.tipo}}</strong>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t">
      <e-col>
        <erp-s-field view="tl" label="Resultado">
          <pre>{{analise.analise}}</pre>
        </erp-s-field>
      </e-col>
    </e-row>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField";
export default {
  name: 'ArrematanteAnaliseContainer',
  props: {
    analise: {required: true}
  },
  data () {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
  components: {
    ErpSField,
    ERow,
    ECol
  }
}
</script>
